import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";


export default function Footer() {
    const phoneNumber = "+966500780131"; // Replace with your phone number
    const message = `انا مهتم بخدماتكم ممكن تفاصيل اكتر ,,
    متجر الادوات ممكن تفاصيل,, اكتر`
   const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    return (
        <footer className="bg-purple text-white py-4 px-3 mt-16 ">
            <div className="container mx-auto flex gap-5 flex-wrap items-center justify-between">
                <div className='flex items-center gap-5'>
                    <FaWhatsapp className='text-3xl cursor-pointer hover:text-gray-300 transition duration-300' onClick={() => window.open(whatsappUrl, '_blank')} />
                    <FaInstagram className='text-3xl cursor-pointer hover:text-gray-300 transition duration-300' />
                    <AiFillTikTok className='text-3xl cursor-pointer hover:text-gray-300 transition duration-300' />
                </div>

                <p className="text-xs  md:text-sm">
                    جميع الحقوق محفوظة © 2024                    </p>

                <button onClick={() => window.open(whatsappUrl, '_blank')} class="bg-white text-purple  hover:bg-purple hover:text-white hover:border font-bold py-2 px-6 rounded" style={{borderColor:"white"}}>
                    للمواعيد
                </button>
            </div>
        </footer>

    )
}
