import React from 'react'
import "./style.css"
export default function Index() {
    const phoneNumber = "+966500780131"; // Replace with your phone number
    const message = `انا مهتم بخدماتكم ممكن تفاصيل اكتر ,,
    متجر الادوات ممكن تفاصيل,, اكتر`
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    return (
        <div className='h-[50vh] index flex flex-col items-center justify-center gap-8'>

            <h1 className='text-4xl text-white font-bold '>  we can team
            </h1>
            <h4 className='text-2xl text-white font-bold'>(معا نستطيع)</h4>

            <button onClick={() => window.open(whatsappUrl, '_blank')} class="bg-purple text-xl lg:text-2xl  px-10 hover:bg-white hover:text-purple hover:border text-white font-bold py-2 px-4 rounded" style={{ borderColor: "purple" }}>
                للمواعيد
            </button>
        </div>
    )
}
