import React from 'react'
import Video from '../../components/youtube-video/Video'
import Services from '../../components/services/Services'
import Why from '../../components/why/Why'
import Index from '../../components/index/Index'
import { Grid } from '@mui/material'
import Reviews from '../../components/reviews/Reviews'
import Store from '../../components/store/Store'
import img1 from "../../assets/images/store/1.jpg"
import img2 from "../../assets/images/store/2.jpg"
import img3 from "../../assets/images/store/3.jpg"
import img4 from "../../assets/images/store/4.jpg"
import img5 from "../../assets/images/store/5.jpg"
import img6 from "../../assets/images/store/6.jpg"
import img7 from "../../assets/images/store/7.jpg"
import img8 from "../../assets/images/store/8.jpg"
import img9 from "../../assets/images/store/9.jpg"
import img10 from "../../assets/images/store/10.jpg"
import img11 from "../../assets/images/store/11.jpg"
import img12 from "../../assets/images/store/12.jpg"
import img13 from "../../assets/images/store/13.jpg"
import img14 from "../../assets/images/store/14.jpg"
import img15 from "../../assets/images/store/15.jpg"
import img16 from "../../assets/images/store/16.jpg"
import img17 from "../../assets/images/store/17.jpg"
import img18 from "../../assets/images/store/18.jpg"
import img19 from "../../assets/images/store/19.jpg"
import img20 from "../../assets/images/store/20.jpg"
import img21 from "../../assets/images/store/21.jpg"
import img22 from "../../assets/images/store/22.jpg"
import img23 from "../../assets/images/store/23.jpg"
import img24 from "../../assets/images/store/24.jpg"
import img25 from "../../assets/images/store/25.jpg"
import img26 from "../../assets/images/store/26.jpg"
import img27 from "../../assets/images/store/27.jpg"
import img28 from "../../assets/images/store/28.jpg"
import img29 from "../../assets/images/store/29.jpg"
import img30 from "../../assets/images/store/30.jpg"
import img31 from "../../assets/images/store/31.jpg"
import img32 from "../../assets/images/store/32.jpg"
import img33 from "../../assets/images/store/33.jpg"
import img34 from "../../assets/images/store/34.jpg"
import img35 from "../../assets/images/store/35.jpg"

export default function Page() {
  let data = [
    {
      text: "الله يبارك لك فيها"
    },
    {
      text: "الله يعطيك العافية . والله ادري فوق راسي انت . انت ماتدري ان ماجد معك كثير طلع حروف واقدر جهودك"
    },
    {
      text: "👆👆حالات اضطرابات النطق"
    },
    {
      text: "تمام شكرا جزيلا ع ردودك الدائمه الله يكتب اجرك"
    },
    {
      text: "ما شاء الله حلو مره حلو جزاك الله خير"
    },
    {
      text: "👆👆طريقتنا في التدريب مبتكرة دائما"
    },
    {
      text: "جزاكم الله خير الجزاء"
    },
    {
      text: "حاجات روعه روعه روعه"
    },
    {
      text: "الله يبارك لك"
    },
    {
      text: "أفكارك جميلة و مبدعه"
    },
    {
      text: "دايما رائعه"
    },
    {
      text: "انا معاك قلبا و قالبا"
    },
    {
      text: "شاكر ومقدر لك استاذ احمد على حسن التعاون"
    },
    {
      text: "ان شاءالله وشكرا لكم على حسن التعامل"
    },
    
    {
      text: "استلمت الشحنه اليوم شكراا جداا"
    },
  
   
  ]
  let data2 = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img29, img30, img31, img32, img33, img34, img35
  ]
  return (
    <div>
      <Index />
      <Grid container className='flex items-center justify-center'>
        <Grid item xs={11} sm={10} className='items-center '>
          <Why />
          <Services />
          <Store data={data2} />
          <Video />
          <Reviews data={data} />
        </Grid>
      </Grid>
    </div>
  )
}
