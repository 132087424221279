import React from 'react'
import Reviews from "../reviews/Reviews"
export default function Store(props) {
  return (
    <div id='store' className='mb-10'>
                 <h1 className='text-4xl text-center font-bold'>متجرنا</h1>
                 <Reviews data={props.data}/>

    </div>
  )
}
