import './App.css';
import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"
import { Outlet } from 'react-router-dom';
import './index.css';
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlinePhoneInTalk } from "react-icons/md";

function App() {
  const phoneNumber = "+966500780131"; // Replace with your phone number
  const phoneNumbe2 = "+966564400734"; // Replace with your phone number

  const message = `انا مهتم بخدماتكم ممكن تفاصيل اكتر ,,
متجر الادوات ممكن تفاصيل,, اكتر`
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  const whatsappUrl2 = `https://wa.me/${phoneNumbe2}?text=${encodeURIComponent(message)}`;

  const call = "+966570562097"
  return (
    <div className="App">

      <Header />
      <div className=" ">
        <Outlet />
      </div>
      <Footer />
      <FaWhatsapp onClick={() => window.open(whatsappUrl2, '_blank')}
        className='fixed right-10 bottom-32 mb-3 text-[45px] cursor-pointer p-2 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition duration-300 ease-in-out'
      />
      <FaWhatsapp onClick={() => window.open(whatsappUrl, '_blank')}
        className='fixed right-10 bottom-20 text-[45px] bg cursor-pointer p-2 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition duration-300 ease-in-out' />
      <MdOutlinePhoneInTalk className='fixed right-10 bottom-5 text-purple text-[45px] cursor-pointer flex items-center justify-center bg-gray-200 hover:bg-gray-100 p-2 rounded-full shadow-lg transition duration-300 ease-in-out' onClick={() => window.location.href = `tel:${call}`}
      />

    </div>
  );
}

export default App;
