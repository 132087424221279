import React from 'react'
import video from "../../assets/images/services/1.mp4"
import img1 from "../../assets/images/services/3.jpg"
import img2 from "../../assets/images/services/2.jpg"
import { Grid } from '@mui/material'
import "./style.css"
export default function Services() {
    let data = [
        {
            title: "علاج النطق و التخاطب"

        },
       

        {
            img: img2,
            title: "صعوبات التعلم"

        },
        {
            img: img1,
            title: "تعديل سلوك"

        },
       
      
       
    ]
    console.log(video)
    return (
        <div className='mb-20 flex items-center flex-col ' id="services">
            <h1 className='text-4xl text-center font-bold'>خدماتنا</h1>

            <Grid container className='w-full flex gap-10 justify-center'>
                {
                    data.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={6} md={3.5} lg={3.2} key={index} className="flex justify-center gap-10 box ">
                                <div className="w-full rounded-lg overflow-hidden shadow-sm hover:shadow-md mt-10">
                                    {
                                        index == 0 ? <video className='w-full h-[220px]' controls autoPlay>
                                            <source src={video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                            :
                                            <img
                                                className="w-full h-[220px]"
                                                src={item.img}
                                                alt="Sunset in the mountains"
                                            />
                                    }

                                    <div className="px-6 py-4">
                                        <div className="font-bold text-center text-xl mb-2">{item.title}</div>
                                    </div>
                                </div>

                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}
