import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";

import { FaRegStar } from "react-icons/fa6";
import "./style.css"
import { RiDoubleQuotesL } from "react-icons/ri";

function Carousel(props) {

    const phoneNumber = "+966500780131"; // Replace with your phone number
    const message = "انا مهتم بخدماتكم و متجر الادوات و ممكن التفاصيل ؟"; // Custom message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const settings = {
        infinite: true,           // Enables infinite scrolling
        speed: 500,               // Transition speed in milliseconds
        slidesToShow: 3,          // Number of slides to show at once
        slidesToScroll: 1,        // Number of slides to scroll at once
        autoplay: true,           // Enables autoplay
        autoplaySpeed: 2000,      // Autoplay interval in milliseconds
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="carousel mt-10">
            <Slider {...settings} >
                {
                    props.data.map((item, index) => {
                        return (
                            <div>
                                {
                                    item.text ? <article key={index} className='flex flex-col gap-20 h-[160px] p-5 bg-gray-100 rounded-lg relative' >
                                        <RiDoubleQuotesL className='before' />

                                        <div>
                                            <div className="flex items-center mb-1 space-x-1 rtl:space-x-reverse ">
                                                <FaStar style={{ color: "orange" }} /><FaStar style={{ color: "orange" }} /><FaStar style={{ color: "orange" }} /><FaStar style={{ color: "orange" }} /> <FaRegStar style={{ color: "orange" }} />

                                            </div>

                                            <p className="mb-3 mt-3 text-gray-500 dark:text-gray-400">
                                                {item.text}
                                            </p>
                                        </div>



                                        <RiDoubleQuotesL className='after' />

                                    </article> :
                                        <div className='w-260px'>
                                            <img src={item} alt="..." className='w-full h-[270px] rounded-md cursor-pointer'  onClick={() => window.open(whatsappUrl, '_blank')} />
                                        </div>
                                }
                            </div>
                        )
                    })
                }

            </Slider>
        </div>
    );
}

export default Carousel;
