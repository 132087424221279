import { Grid } from '@mui/material'
import React from 'react'
import { FaUsers } from "react-icons/fa";
import { FaBook } from "react-icons/fa6";
import { GiGraduateCap } from "react-icons/gi";
import "../services/style.css"
import { PiHandshakeBold } from "react-icons/pi";

export default function Why() {
    let data = [
        {
            icon: <PiHandshakeBold className='text-5xl m-auto' />,
            title: "أخصائيين وكوادر مصريين و اردنيين"

        },
        {
            icon: <FaBook className='text-5xl m-auto' />,
            title: " خبرات واسعة ومميزة في مجال تأهيل الأطفال "

        },
        {
            icon: <GiGraduateCap className='text-5xl m-auto' />,
            title: "حاصلين على الصلاحية و الرخصة المهنية من التعليم السعودي"
        },
    ]
    return (
        <div>
            <div className='mb-20 flex items-center flex-col'>
                <h1 className='text-4xl text-center font-bold'  >من نحن</h1>

                <Grid container className='w-full flex justify-center gap-10 mt-20'>
                    {
                        data.map((item, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={3.5} lg={3.2} key={index} className="flex justify-center gap-10  box ">

                                    <div className="flex justify-center gap-5  flex-col">
                                        <div className='text-center '> {item.icon}</div>

                                        <div className="text-center text-base mb-2 text-gray-400 w-[230px] lg:w-[250px]">
                                            {item.title}
                                        </div>
                                    </div>

                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </div>
    )
}
