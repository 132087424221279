import { Grid } from '@mui/material'
import React from 'react'
import video from "./video.mp4"

export default function Video() {
    return (
        <div id='success'>
            <Grid container className='flex justify-center'>
                <Grid item xs={11.5} md={8}>
                    <video className=' w-full m-auto sm:h-[50vh]  lg:h-[60vh]' controls autoPlay>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Grid>
            </Grid>
        </div>
    )
}
